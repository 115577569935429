import {
    required,
    minLength,
    maxLength,
    alpha,
    sameAs,
    alphaNum,
    email,
    numeric
  } from 'vuelidate/lib/validators';
  
  import { alphaNumSpecials } from '../funciones';
  
  const emailToLower = (value) => email(value.toLowerCase());

  export default () => {
    return {
      user: {
        login: { required, alpha, minLength: minLength(4), maxLength: maxLength(50) },
        email: { required, email: emailToLower, maxLength: maxLength(100) },
        code:  { required, alphaNum },
        password: { required, alphaNumSpecials, minLength: minLength(6), maxLength: maxLength(20) },
        confirmPassword: { sameAsPassword: sameAs('password') }
      },
      validateToken: { required },
      cedula: { required, numeric, minLength: minLength(7), maxLength: maxLength(8)},
      ciType: { }
    }
  }